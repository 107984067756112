$card-outline-color: #7b00ff7c; // Define your custom outline color infoband: #89c3a2

.custom-card {
    border: 3px solid $card-outline-color; // Set the outline color
    border-radius: 0.5rem; // Ensure rounded corners match Bootstrap's card

    &.shadow-lg {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // You can keep the existing shadow if you want
    }
}

$custom-outline-color: #7b00ff;

.custom-outline-btn {
    border: 2px solid $custom-outline-color; // Set custom outline color
    color: $custom-outline-color; // Text color
    background-color: transparent; // Background color

    &:hover {
        background-color: $custom-outline-color; // Change background on hover
        color: #fff; // Change text color on hover
    }
}

.container_vertical_align {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.text_border{
    text-shadow: 0 0 2px rgb(0, 0, 0);
}

.grid {
    grid-template-columns: 350px 200px 250px 1fr;
    grid-template-rows: 550px 1fr;
    display: grid;
    gap: 5px 5px;
    overflow-y: hidden;
    overflow-x: scroll;
    // align-items: stretch;
    // justify-content: center;
    // align-content: center;
    // grid-auto-flow: dense;

}

.newday {
    grid-column: 3 / span 2;
    grid-row: 2;
}

.nyt {
    grid-column: 1 / span 2;
    grid-row: 1;
    // align-self: end;
    justify-self: start;
}

.eva {
    grid-column: 1 / span 3;
    grid-row: 2;
    align-self: start;
    // justify-self: start;
}

.aime {
    grid-column: 4;
    grid-row: 1;
    max-height: 550px;
    overflow: hidden;
}

.nmsqt {
    grid-column: 3;
    grid-row: 1;
}

// .infoband {
//     grid-column: 1 / span 3;
// }

.flex-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    // align-items: stretch; //stretch
    align-content: stretch;
    gap: 5px;
}